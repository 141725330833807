// Core
import React, { useRef, useState, useEffect } from "react"
import Hammer from "react-hammerjs"

import { InView } from "react-intersection-observer"
import { isMobile } from "react-device-detect"

// Components
import Button from "../button/button"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"
import SplitText from "../split-text/split-text"

// Styles
import styles from "./feature.module.scss"

const Feature = props => {
  //content variables
  const { data } = props,
    title = data.opportunityAreasTitle,
    buttonProps = data.opportunityAreasButton

  // opportunityAreasTitle
  // opportunityAreasButtonText
  // opportunityAreasButtonUrl
  // opportunityAreasArea1Title
  // opportunityAreasArea2Title
  // opportunityAreasArea3Title

  const contentList = [
    {
      title: data.opportunityAreasArea1Title,
      imgSrc: data.opportunityAreasArea1Image,
    },
    {
      title: data.opportunityAreasArea2Title,
      imgSrc: data.opportunityAreasArea2Image,
    },
    {
      title: data.opportunityAreasArea3Title,
      imgSrc: data.opportunityAreasArea3Image,
    },
  ]

  let buttonTheme = "light"

  let btnProps = {
    buttonProps,
    buttonTheme,
  }

  const initialActive = 1,
    max = 3

  const [currentActive, setCurrentActive] = useState(initialActive)

  const carousel = useRef()

  const handleSwipe = e => {
    if (isMobile) {
      if (e.direction === 4) {
        //left
        // console.log("mobile left swipe")
        if (currentActive === 1) return
        setCurrentActive(currentActive - 1)
      }
      if (e.direction === 2) {
        //right
        // console.log("mobile right swipe")
        if (currentActive === max) return
        setCurrentActive(currentActive + 1)
      }
    } else return
  }

  useEffect(() => {
    const resizeFunction = () => {
      if (!isMobile) {
        setCurrentActive(initialActive)
        // console.log(currentActive)
      }
    }

    window.addEventListener("resize", resizeFunction)

    return () => {
      window.removeEventListener("resize", resizeFunction)
    }
  }, [])

  return (
    <ScrollSection>
      <section className={styles.feature} data-active={currentActive}>
        <div className="container">
          <div className="cols flex flex--center">
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 ${styles.text}`}
                  ref={ref}
                  inview={inView.toString()}
                >
                  <div data-scroll data-scroll-speed={0.6}>
                    <SplitHeading classString={`h3 ${styles.heading}`}>
                      {title}
                    </SplitHeading>
                    <Button {...btnProps} />
                  </div>
                </div>
              )}
            </InView>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 ${styles.carouselwrap}`}
                  data-scroll
                  ref={ref}
                  inview={inView.toString()}
                >
                  <Hammer onSwipe={handleSwipe}>
                    <div className={styles.carousel} ref={carousel}>
                      <ul>
                        {contentList.map((card, index) => (
                          <li key={index} className={styles.carouselitem}>
                            <div
                              className={styles.imageWrap}
                              data-scroll
                              data-scroll-speed={0.5}
                            >
                              <img
                                src={card.imgSrc.fluid.src}
                                alt={card.imgSrc.description}
                                height="365"
                                width="365"
                              />
                            </div>
                            <SplitText
                              classString={`subheading ${styles.subheading}`}
                            >
                              {card.title}
                            </SplitText>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Hammer>
                  <div className={styles.marker}>
                    {contentList.map((card, index) => (
                      <span key={index}>
                        <span></span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </InView>
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Feature
