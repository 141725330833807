import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ContextDispatch } from "./../context/Context"

//Components
import Footer from "../components/footer/footer"
import Feature from "../components/feature/feature"
import Hero from "../components/home-hero/home-hero"
import NextPage from "../components/next-page/next-page"
import TextAnimation from "../components/text-animation/text-animation"
import TextImage from "../components/text-image/text-image"
import SEO from "../components/seo/seo"

const IndexPage = props => {
  const page = props
  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      if (window.innerWidth < 1024) {
        storeDispatch({ type: "HEADER_DARK" })
      } else {
        storeDispatch({ type: "HEADER_LIGHT" })
      }
    }
    header()

    window.addEventListener("resize", header)

    return () => {
      window.removeEventListener("resize", header)
    }
  }, [storeDispatch])

  let nextpageblock

  if (page.data.allContentfulHomePage.edges[0].node.nextPage) {
    nextpageblock = (
      <NextPage
        heading={page.data.allContentfulHomePage.edges[0].node.nextPage.heading}
        title={page.data.allContentfulHomePage.edges[0].node.nextPage.linkTitle}
        url={
          "/" +
          page.data.allContentfulHomePage.edges[0].node.nextPage.page.slug +
          "/"
        }
      />
    )
  }
  return (
    <React.Fragment>
      <SEO
        title={page.data.allContentfulHomePage.edges[0].node.seo.seoTitle}
        image={page.data.allContentfulHomePage.edges[0].node.seo.seoImage}
        description={
          page.data.allContentfulHomePage.edges[0].node.seo.seoDescription
        }
      />

      <Hero
        title={page.data.allContentfulHomePage.edges[0].node.heroTitle}
        subHeading={
          page.data.allContentfulHomePage.edges[0].node.heroSubheading
        }
        imageSrc={page.data.allContentfulHomePage.edges[0].node.heroImage}
        video={page.data.allContentfulHomePage.edges[0].node.heroVideoVimeoCode}
      />
      <TextImage
        largeImage={`false`}
        imgAlign={`right`}
        theme={`#172138`}
        copy={
          page.data.allContentfulHomePage.edges[0].node.textImageCopy1
            .textImageCopy1
        }
        buttonProps={
          page.data.allContentfulHomePage.edges[0].node.textImageButton
        }
        imageSrc={page.data.allContentfulHomePage.edges[0].node.textImageImage}
        videoUrl={
          page.data.allContentfulHomePage.edges[0].node.textImageVimeoCode
        }
      />
      <TextAnimation
        bgColour={`#172138`}
        textAlignment={`right`}
        copy={page.data.allContentfulHomePage.edges[0].node.textAnimationCopy}
        buttonProps={
          page.data.allContentfulHomePage.edges[0].node.textAnimationButton
        }
      />
      <TextImage
        largeImage={`true`}
        imgAlign={`left`}
        theme={`#2431B3`}
        copy={page.data.allContentfulHomePage.edges[0].node.textLargeImageCopy}
        buttonProps={
          page.data.allContentfulHomePage.edges[0].node.textLargeImageButton
        }
        imageSrc={
          page.data.allContentfulHomePage.edges[0].node.textLargeImageImage
        }
        videoUrl={
          page.data.allContentfulHomePage.edges[0].node.textLargeImageVimeoCode
        }
      />
      <Feature data={page.data.allContentfulHomePage.edges[0].node} />
      {nextpageblock}

      <Footer />
    </React.Fragment>
  )
}

export default IndexPage

export const homeQuery = graphql`
  query HomePage {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHomePage(limit: 1, filter: { slug: { ne: "dummyhome" } }) {
      edges {
        node {
          pageTitle
          slug
          contentful_id
          heroSubheading
          heroTitle
          heroImage {
            fluid(maxWidth: 900, quality: 80) {
              src
              srcSet
              ...GatsbyContentfulFluid_noBase64
            }
            description
          }
          heroVideoVimeoCode
          textImageCopy1 {
            textImageCopy1
          }
          textImageButton {
            buttonText
            typeOfLink {
              ... on ContentfulExternalLink {
                id
                buttonDestination
                buttonTarget
              }
              ... on ContentfulHomePage {
                id
                slug
              }
              ... on ContentfulTemplate1 {
                id
                slug
              }
              ... on ContentfulTemplate2 {
                id
                slug
              }
              ... on ContentfulTemplate3 {
                id
                slug
              }
            }
          }
          textImageImage {
            fluid(maxWidth: 1680, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
            description
          }
          textImageVimeoCode
          textAnimationCopy
          textAnimationButton {
            buttonText
            typeOfLink {
              ... on ContentfulExternalLink {
                id
                buttonDestination
                buttonTarget
              }
              ... on ContentfulHomePage {
                id
                slug
              }
              ... on ContentfulTemplate1 {
                id
                slug
              }
              ... on ContentfulTemplate2 {
                id
                slug
              }
              ... on ContentfulTemplate3 {
                id
                slug
              }
            }
          }
          textLargeImageCopy
          textLargeImageButton {
            buttonText
            typeOfLink {
              ... on ContentfulExternalLink {
                id
                buttonDestination
                buttonTarget
              }
              ... on ContentfulHomePage {
                id
                slug
              }
              ... on ContentfulTemplate1 {
                id
                slug
              }
              ... on ContentfulTemplate2 {
                id
                slug
              }
              ... on ContentfulTemplate3 {
                id
                slug
              }
            }
          }
          textLargeImageImage {
            fluid(maxWidth: 1680, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
            description
          }
          textLargeImageVimeoCode
          opportunityAreasTitle
          opportunityAreasButton {
            buttonText
            typeOfLink {
              ... on ContentfulExternalLink {
                id
                buttonDestination
                buttonTarget
              }
              ... on ContentfulHomePage {
                id
                slug
              }
              ... on ContentfulTemplate1 {
                id
                slug
              }
              ... on ContentfulTemplate2 {
                id
                slug
              }
              ... on ContentfulTemplate3 {
                id
                slug
              }
            }
          }
          opportunityAreasArea1Title
          opportunityAreasArea2Title
          opportunityAreasArea3Title
          opportunityAreasArea1Image {
            description
            fluid(maxWidth: 730, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
              src
            }
          }
          opportunityAreasArea2Image {
            fluid(maxWidth: 730, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
              src
            }
            description
          }
          opportunityAreasArea3Image {
            description
            fluid(maxWidth: 730, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
              src
            }
          }
          nextPage {
            heading
            linkTitle
            page {
              ... on ContentfulHomePage {
                id
                slug
              }
              ... on ContentfulTemplate1 {
                id
                slug
              }
              ... on ContentfulTemplate2 {
                id
                slug
              }
              ... on ContentfulTemplate3 {
                id
                slug
              }
            }
          }
          seo {
            seoTitle
            seoDescription
            seoImage {
              fluid(
                cropFocus: CENTER
                resizingBehavior: FILL
                quality: 65
                maxHeight: 630
                maxWidth: 1200
              ) {
                aspectRatio
                src
                srcSet
                sizes
              }
              description
            }
          }
        }
      }
    }
  }
`
