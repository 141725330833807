// Core
import React, { useRef, useEffect } from "react"
import lottie from "lottie-web"

//animation data
import animationData from "../../../static/strategy-animation/Aegis-HP.json"

// InView detector
import { InView } from "react-intersection-observer"

// import { isMobile } from "react-device-detect"
// import img from "./animation/images/"

// Styles
import styles from "./index.module.scss"

const Animation = props => {
  const animationRef = useRef(),
        animationObj = useRef()

  const playAnimation = x => {
    if (x) {
      animationObj.current.play()
    }
  }

  useEffect(() => {
    animationObj.current = lottie.loadAnimation({
      container: animationRef.current, // the dom element that will contain the animation
      loop: false,
      autoplay: false,
      animationData: animationData, // the path to the animation json
    })

    return () => {
      animationObj.current.destroy()
    }
  }, [animationObj])

  return (
    <InView triggerOnce={true} threshold={0.2} onChange={playAnimation}>
      {({ inView, ref, entry }) => (
        <div data-scroll ref={ref} inview={inView.toString()}>
          <div className={`${styles.animation}`} ref={animationRef}></div>
        </div>
      )}
    </InView>
  )
}

export default Animation
