// Core
import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

// Components
import VideoComponent from "./../video/video"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"

// Styles
import styles from "./home-hero.module.scss"

const Hero = props => {
  const { video } = props,
    { title } = props,
    { subHeading } = props,
    { imageSrc } = props

  let mediaContent

  const mask = useRef()

  // let tempVideo = `521084029`

  if (video) {
    //   //return vimeo component
    mediaContent = (
      <VideoComponent
        videoProps={video}
        fluid={imageSrc.fluid}
        alt={imageSrc.description}
        home={true}
      />
    )
  } else {
    //return static image component
    mediaContent = <Img fluid={imageSrc.fluid} alt={imageSrc.description} />
  }

  const scrollOn = () => {
    if (window.scroll.on) {
      window.scroll.on("scroll", args => {
        if (mask.current)
          mask.current.style.transform =
            "scaleX(" + args.scroll.y / window.innerHeight + ")"
      })
    }
  }

  const scrollOff = () => {
    if (window.scroll.off) {
      window.scroll.off("scroll", args => {
        if (mask.current)
          mask.current.style.transform =
            "scaleX(" + args.scroll.y / window.innerHeight + ")"
      })
    }
  }

  useEffect(() => {
    // console.log("use effect")
    scrollOn()

    return () => {
      scrollOff()
    }
  })

  return (
    <ScrollSection>
      {" "}
      <InView triggerOnce={true} threshold={0.2}>
        {({ inView, ref, entry }) => (
          <section className={styles.hero} ref={ref} inview={inView.toString()}>
            <div className="container">
              <InView triggerOnce={true} threshold={0.2}>
                {({ inView, ref, entry }) => (
                  <div className={`cols flex ${styles.cols}`}>
                    <div
                      className={`col m12 t6 ${styles.text}`}
                      ref={ref}
                      inview={inView.toString()}
                    >
                      <div data-scroll data-scroll-speed={1}>
                        <span className={`subheading ${styles.subheading}`}>
                          {subHeading}
                        </span>
                      </div>
                      <div data-scroll data-scroll-speed={1}>
                        <SplitHeading classString={styles.heading} h1={true}>
                          {title}
                        </SplitHeading>
                      </div>
                    </div>
                    <div className={`col m12 t6 ${styles.heromedia}`}>
                      <div
                        className={styles.heroImage}
                        inview={inView.toString()}
                      >
                        <div className={styles.heroImageContainer}>
                          <div
                            className={styles.heroImageMask}
                            ref={mask}
                          ></div>
                          <div className={styles.heroImageInner}>
                            {mediaContent}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </InView>
            </div>
          </section>
        )}
      </InView>
    </ScrollSection>
  )
}

export default Hero
