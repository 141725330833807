// Core
import React, { useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { ContextDispatch } from "./../../context/Context"

//SVG
// import Arrow from "./../../static/images/svg/button.inline.svg"
import Circle from "./../../../static/images/svg/button-circle.inline.svg"
import Arrow from "./../../../static/images/svg/arrow.inline.svg"

// Styles
import styles from "./button.module.scss"

/*
 ** Requires buttonText and buttonUrl, buttonTheme is optional (light)
 ** Dark theme is default
 */

const Button = props => {
  const { buttonProps } = props

  let internal, buttonTheme, buttonUrl, buttonTarget, buttonText, buttonType

  if (buttonProps) {
    buttonText = buttonProps.buttonText
    buttonType = buttonProps.typeOfLink

    if (!buttonType.__typename) {
      internal = true
      buttonUrl = buttonType.slug
    } else if (
      buttonType.__typename === "ContentfulTemplate1" ||
      buttonType.__typename === "ContentfulTemplate2" ||
      buttonType.__typename === "ContentfulTemplate3" ||
      buttonType.__typename === "ContentfulHeathcareAiPage" ||
      buttonType.__typename === "ContentfulPressPage" ||
      buttonType.__typename === "ContentfulTextOnlyTemplate"
    ) {
      internal = true
      buttonUrl = buttonType.slug
    } else if (buttonType.buttonDestination) {
      internal = false
      buttonUrl = buttonType.buttonDestination
      if (buttonType.buttonTarget === "New Tab") buttonTarget = true
    }

    if (buttonType.buttonDestination) {
      internal = false
      buttonUrl = buttonType.buttonDestination
      if (buttonType.buttonTarget === "New Tab") buttonTarget = true
    }
  }

  if (props.buttonTheme) {
    buttonTheme = props.buttonTheme
  }

  const storeDispatch = useContext(ContextDispatch)

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  if (internal) {
    return (
      <TransitionLink
        to={`/` + buttonUrl}
        className={`button ${buttonTheme ? `button--${buttonTheme}` : ``}  ${
          styles.link
        }`}
        exit={{
          trigger: ({ exit, node }) => exitAnim(exit, node),
          length: 0.6,
          zIndex: 2,
        }}
        entry={{
          trigger: ({ entry, node }) => entryAnim(entry, node),
          delay: 1,
          length: 1,
          zIndex: 1,
        }}
      >
        <span>
          <span className={styles.circle}>
            <Circle />
            <span className={styles.arrowWrap}>
              <span className={styles.arrow}>
                <Arrow />
              </span>
              <span className={styles.arrow}>
                <Arrow />
              </span>
            </span>
          </span>
          <span className={styles.buttonText}>
            <span className="btn-text-css">{buttonText}</span>
          </span>
        </span>
      </TransitionLink>
    )
  } else {
    return (
      <a
        href={buttonUrl}
        target={buttonTarget ? `_blank` : ``}
        rel={buttonTarget ? `noreferrer` : ``}
        className={`button ${buttonTheme ? `button--${buttonTheme}` : ``}  ${
          styles.link
        }`}
      >
        <span>
          <span className={styles.circle}>
            <Circle />
            <span className={styles.arrowWrap}>
              <span className={styles.arrow}>
                <Arrow />
              </span>
              <span className={styles.arrow}>
                <Arrow />
              </span>
            </span>
          </span>
          <span className={styles.buttonText}>
            <span>{buttonText}</span>
          </span>
        </span>
      </a>
    )
  }
}

export default Button
