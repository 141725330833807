// Core
import React from "react"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import Button from "../button/button"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitText from "../split-text/split-text"
import VideoComponent from "./../video/video"

//SVG

import Circle from "./../../../static/images/svg/pxcircle.inline.svg"

// Styles
import styles from "./text-image.module.scss"
// import Image from "../../images/home/home-1.jpg"

const TextImage = props => {
  const { largeImage } = props,
    { theme } = props,
    { imgAlign } = props,
    { buttonTheme } = props,
    { copy } = props,
    { buttonProps } = props,
    { imageSrc } = props,
    { videoUrl } = props

  const colourScheme = theme,
    classString = `copy copy--m ${styles.copy}`

  let p

  if (!copy) {
    p =
      "Aegis Labs is an emerging venture studio that partners with entrepreneurs and industry leaders to originate, launch, and scale transformative companies."
  } else {
    p = copy
  }

  let btnProps = {
    buttonProps,
    buttonTheme,
  }

  let imgClassString, textClassString

  //small image is default
  if (largeImage === false || largeImage === "false") {
    imgClassString = "col m12 t6"
    textClassString = "col m12 t6"
  } else {
    imgClassString = "col m12 t8"
    textClassString = "col m12 t4"
  }

  let mediaContent

  if (videoUrl) {
    //   //return vimeo component
    mediaContent = (
      <VideoComponent
        videoProps={videoUrl}
        fluid={imageSrc.fluid}
        alt={imageSrc.description}
      />
    )
  } else {
    //return static image component

    mediaContent = <Img fluid={imageSrc.fluid} alt={imageSrc.description} />
  }

  return (
    <ScrollSection>
      <section
        className={`${styles.textimage} ${
          largeImage === `true` ? styles.textimagelarge : ``
        }`}
        data-image-alignment={imgAlign}
      >
        <div className="container">
          <div className="cols flex">
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div className={`${textClassString} ${styles.text}`}>
                  <div
                    data-scroll
                    data-scroll-speed={1}
                    data-scroll-delay={0.01}
                    ref={ref}
                    inview={inView.toString()}
                  >
                    <SplitText classString={classString}>{p}</SplitText>
                    <Button {...btnProps} />
                  </div>
                </div>
              )}
            </InView>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div className={`${imgClassString} ${styles.media}`}>
                  <div
                    className={styles.mediawrap}
                    ref={ref}
                    inview={inView.toString()}
                    data-scroll
                    data-scroll-speed={0.6}
                    data-scroll-delay={0.6}
                    style={{
                      backgroundColor:
                        colourScheme === "#2431B3" ? `#172138` : `#2431B3`,
                    }}
                  >
                    <div className={styles.maskOuter}>
                      <div className={styles.maskInner}>
                        {mediaContent}
                        <Circle />
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.mediabg}
                    style={{ backgroundColor: `${colourScheme}` }}
                  ></div>
                </div>
              )}
            </InView>
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default TextImage
